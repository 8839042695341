.myBtn {
  position: fixed;
  z-index: 1000;
  right: 2rem;
  cursor: pointer;
  padding: 1rem 1.3rem;
  margin: 0;
  border: none;
  background-color: #fbc02d;
  border-radius: 51%;
  outline: none;
}

.myBtn:hover {
  background-color: #fbc846 !important;
  outline: none;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.2), 0 4px 15px 0 rgba(0, 0, 0, 0.2);
}

.bottomWithFooter {
  bottom: 4rem;
}

.bottomWithoutFooter {
  bottom: 1rem;
}

.faIcon {
  color: white;
}
