@import '../../../globalStyles.scss';

.section-body {
  height: 550px;
}

#data-details-btn {
  background-color: $orangeDarken4 !important;
}

.reorder1,
.reorder2,
.reorder3,
.reorder4 {
  display: inline-block;
}

@media screen and (max-width: 577px) {
  .reorder1 {
    order: 3;
  }
  .reorder2 {
    order: 2;
  }
  .reorder3 {
    order: 1;
  }
  .reorder4 {
    order: 4;
  }
}
