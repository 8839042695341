@import '../../../globalStyles.scss';

.backTo {
  color: $orangeDarken4;
  font-weight: 500;
  margin: 0;
  padding: 0;
  opacity: 0.8;
  box-shadow: none;

  &:hover {
    color: $orangeDarken4;
    opacity: 1;
    text-decoration: underline;
  }
}
