@import '../../../globalStyles.scss';

.highcharts-data-table th {
  font-weight: bold !important;
}

.highcharts-data-table table {
  border-collapse: collapse;
  border-spacing: 0;
  background: white;
  min-width: 100%;
  margin-top: 10px;
  font-size: 0.9em;
  word-break: break-word;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  border: 1px solid grey;
  padding: 0.5em;
}

.highcharts-data-table tr:nth-child(even),
.highcharts-data-table thead tr {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: $orangeLighten4;
}

.highcharts-data-table caption {
  border-bottom: none;
  font-size: 1.1em;
}
