$orangeLighten3: #ffcc80;
$orangeLighten4: #ffa500;
$orangeDark: #ff9933;
$orangeDarken4: #e65100;
$greenLighten: #64be67;
$redLighten: #ff0000;
$grayLighten: #e5e5e5;
$grayLight: #a1aea7;
$grayDark: #595a60;
$grayDarker: #757575;
$warning: #ffc008;

$FontSize12: 12px;
$FontSize14: 14px;
$FontSize20: 20px;
$FontSize40: 40px;

$fontWeight600: 600;

.dropdown-content li > span.filtrable {
  color: black;
}

.dropdown-content li.disabled span.filtrable {
  color: gray;
}

.table.table-hover tbody tr:hover {
  background-color: #fff3e0;
}

.action-text {
  font-size: $FontSize14;
}

.userNotSyncronized-text {
  font-size: $FontSize40;
}

.footer-colors {
  background-color: $grayDark;
  color: $grayLight;
}

.footer-position {
  z-index: 1080;
  position: fixed;
  bottom: 0;
}

.sideNav {
  position: fixed;
  z-index: 1111;
  background-image: linear-gradient(to right, rgba(250, 250, 250, 0.8), rgba(242, 242, 242, 0.8)),
    url('./../public/sidenav-background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@supports (-webkit-touch-callout: none) {
  .sideNav {
    -webkit-overflow-scrolling: touch;
    height: var(--window-inner-height);
  }
}

.sidenav .ps__rail-y {
  display: none !important;
}
.sidenav .ps__thumb-y {
  display: none !important;
}

.overFlowAndHeight {
  overflow: auto;
  height: fit-content;
}

.noTextDecoration {
  text-decoration: none;
}

.export-btn {
  text-decoration: none;
  padding: 7px 19px;
  font-size: $FontSize14;
  color: white;
}

.fontWeight600 {
  font-weight: $fontWeight600;
}

.clear-search-btn {
  margin: 0;
  margin-top: -1px;

  &:hover,
  &:active,
  &:focus {
    margin: 0;
    margin-top: -1px;
  }
}

.chartsContainer {
  background-color: lightgray;
}

.chartBtn-size {
  font-size: $FontSize14;
}

.collapse-header {
  border-color: black;
  background-color: white !important;
  cursor: pointer;
  max-width: 100%;
}

.rsrg-logo {
  width: 60%;
}

.height-0 {
  height: 0px !important;
}

.radio-buttons {
  max-width: fit-content;
  padding-right: 0;
  padding-top: 3%;
}

.group-name-btn {
  color: black !important;
  box-shadow: none !important;
  background-color: transparent !important;
  text-transform: none !important;

  &:hover {
    color: black !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:active {
    background-color: transparent !important;
    color: black !important;
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.autoWidth {
  width: auto;
}

.width90Percent {
  width: 90%;
}

.width80Percent {
  width: 80%;
}

.period-filter-panel-position {
  display: flex;
  margin-top: 2%;
  flex-flow: row;
  align-items: center;
}

.custom-period-wrapper {
  max-width: fit-content;
  padding-right: 0;
  margin-top: 1%;
}

.marginTop-unset {
  margin-top: unset;
}

.filter-wrapper {
  max-width: fit-content;
  padding-right: 0;
  margin-top: 1%;
}

.on-off-wrapper {
  display: flex;
  max-width: fit-content;
  margin-top: 1%;
  margin-left: 1%;
}

.link-decoration {
  text-decoration: none;
}

.margin-minus4 {
  margin: -4px !important;
}

.machineGroups {
  color: black;
  box-shadow: none;
}

.pl-30 {
  padding-left: 30px;
}

.pr-20 {
  padding-right: 20px;
}

.w-24 {
  width: 24px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}
