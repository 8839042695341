@import '../../../../../globalStyles.scss';

.period-filter {
  .filter-label {
    border-top: 2px solid $orangeDarken4;
    border-bottom: 2px solid $orangeDarken4;
  }

  .md-form label {
    font-size: 14px;
    font-weight: 300;
  }
}

.borderColor {
  border: solid;
  border-color: $orangeDarken4;
}

.switch-no-border {
  border: none !important;
}
