@import '../../../../../globalStyles.scss';

.group-machine-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.9rem;
  font-weight: 300;

  .btn-rounded {
    width: 30px;
    height: 30px;
    font-size: 15px;
    padding: 0 !important;
  }
}

.btn-tooltip {
  z-index: 9999;
}

.col-machine-groups-buttons {
  max-width: 128px;

  .detail-buttons {
    max-width: 85px;
  }
}

.min-Width-40{
  min-width: 40%;
}