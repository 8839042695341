.tooltip {
  padding: 9px 16px;
  font-size: 14px;
  border-radius: '4px';
}

.popover_arrow {
  border-color: transparent transparent rgba(97, 97, 97, 0.9) transparent !important;
}

.popover_arrow::before {
  border-color: transparent transparent rgba(97, 97, 97, 0.9) transparent !important;
}

.rsrg-table-cell {
  word-break: break-word;
}

.btn-rounded {
  font-size: 15px !important;
}
