.select-option {
  font-size: 0.8rem;
}

.contract-date-picker {
  background-image: none !important;
}

.contract-detail-form {
  .form-control {
    margin-bottom: 0px;
  }

  .form-check {
    padding: 0px;
  }
}
