.flexible-navbar {
  z-index: 1100;
  position: relative;
  flex-flow: row;

  .side-nav-toggle-a {
    font-size: 1.3333em;
    line-height: 32px;
    margin-left: 1em;
    vertical-align: middle;
    align-self: center;
  }

  .mailto {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.yellow-on-hover {
  &:hover {
    background-color: #ffc107;
    color: white;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  }
}

.machine-name-wrap {
  width: 60rem;
  white-space: normal !important;
}

@media (max-width: 1600px) {
  .machine-name-wrap {
    width: 35rem;
    white-space: normal !important;
  }
}

@media (max-width: 1024px) {
  .machine-name-wrap {
    width: 25rem;
    white-space: normal !important;
  }
}

@media (max-width: 768px) {
  .machine-name-wrap {
    width: 20rem;
    white-space: normal !important;
  }
}

@media (max-width: 576px) {
  .machine-name-wrap {
    width: 8rem;
    white-space: normal !important;
  }
}
