@import '../../../../../globalStyles.scss';

.cancel-onHover:hover {
  background-color: transparent;
  color: rgb(255, 187, 51);
}

.btn-warning.hover,
.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus,
.btn-warning:active,
.btn-warning:active {
  color: white;
  background-color: rgb(255, 187, 51);
  border-color: rgb(255, 187, 51);
}