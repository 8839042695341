.side-navigation-no-machines {
  color: #424242 !important;
  font-weight: 400;
  font-size: 0.8rem;
  padding-left: 56px;
}

.side-navigation-machines {
  padding-left: 56px !important;
}
