@import '../../../../../globalStyles.scss';

.leaflet-control-layers-selector {
  position: relative !important;
  opacity: 1 !important;
}

.leaflet-div-icon {
  background: transparent;
  border: 0;
}

.bounce-marker {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: ease;
}

.markercluster-map {
  height: 90vh;
}

.last-track-history-location {
  filter: invert(0%) sepia(0%) saturate(0%) brightness(0%) contrast(100%);
}

.close-button-tooltip {
  position: absolute;
  top: 0px;
  right: 5px;
  border: none;
  background-color: none;
  background: none;
  color: lightgray;
  font-size: 18px;
  font-weight: bolder;
}

.marker-tooltip {
  opacity: 1 !important;
  padding: 2px !important;
}

.green-text-color-marker-tooltip {
  color: $greenLighten !important;
}

.orange-text-color-marker-tooltip {
  color: $orangeLighten4 !important;
}

.red-text-color-marker-tooltip {
  color: $redLighten !important;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
