@import '../../../globalStyles.scss';

.green-text-color,
.gray-text-color,
.orange-text-color,
.red-text-color {
  color: #fff;
  font-weight: 600;
  padding: 1px 5px;
  border-radius: 4px;
  line-height: 19px;
  font-size: 14px;
  white-space: nowrap;
}

.green-text-color {
  background-color: $greenLighten;
}

.orange-text-color {
  background-color: $orangeLighten4;
}

.red-text-color {
  background-color: $redLighten;
}

.gray-text-color {
  background-color: $grayDarker;
}
