@import '../../../../../globalStyles.scss';

.preloader-wrapper {
  position: unset;
  top: unset;
}

.tooltip {
  padding: 9px 16px;
  font-size: 14px;
  border-radius: '4px';
  max-width: 300px;
}

.processing {
  font-size: 16px;
  color: $orangeDarken4;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
