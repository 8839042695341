@import '../../../../../globalStyles.scss';

.selector-class .form-check {
  padding-left: 0px;
}

.period-filter {
  align-items: flex-start;

  .filter-label,
  .sensorsSlice-label {
    border-top: 2px solid $orangeDarken4;
    border-bottom: 2px solid $orangeDarken4;
  }

  .sensor-duration-label {
    max-width: 100%;
  }

  .background-yellow {
    background-color: rgb(255, 192, 8);
  }

  .md-form {
    margin-bottom: unset !important;
  }

  .md-form label {
    font-size: 14px;
    font-weight: 300;
  }
}

.slices-panel .md-form {
  margin-top: 0;
  width: 240px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.switch-no-border {
  border: none !important;
}

.toggle-options-width {
  width: 6rem;
}

@media (max-width: 1200px) {
  .view-button-my {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

@media (max-width: 780.98px) {
  .date-time-to-col {
    padding-left: 30px;
  }
}

@media (max-width: 576px) {
  .filter-select-wrapper-xs {
    margin-top: unset;
    width: 100%;
  }

  .sensor-duration-label {
    width: 100% !important;
  }
}
