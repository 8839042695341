@import '../../../globalStyles.scss';

.tab-header {
  background-color: $orangeLighten3;

  .tab-header-name {
    flex-grow: 1;
    border-bottom: 7px solid $orangeLighten3;

    &.tab-active {
      border-bottom: 7px solid $orangeDarken4;
    }
  }
}
