@import '../../../../../globalStyles.scss';

.btn-yellow,
.btn-yellow:hover {
  border-color: rgb(245, 124, 0);
  color: rgb(245, 124, 0);
}

.btn-black,
.btn-black:hover {
  border-color: black;
  color: black;
}

.btn-yellow,
// .btn-yellow:hover,
.btn-yellow:active,
.btn-yellow:focus,
.btn-yellow:focus-within,
.btn-yellow:target,
.btn-yellow:visited,
.btn-yellow:focus-visible {
  border: 1px solid;
  border-color: rgb(245, 124, 0);
  color: rgb(245, 124, 0);

  font-size: larger;
  box-shadow: 0 8px 9px -4px rgb(59 113 202 / 30%), 0 4px 18px 0 rgb(59 113 202 / 20%);
  border-width: 2px;
  width: 2rem;
  height: 2rem;
}

.btn-black,
.btn-black:hover,
.btn-black:active,
.btn-black:focus,
.btn-black:focus-within,
.btn-black:target,
.btn-black:visited,
.btn-black:focus-visible {
  border: 1px solid;
  border-color: black;
  color: black;

  font-size: larger;
  box-shadow: 0 8px 9px -4px rgb(59 113 202 / 30%), 0 4px 18px 0 rgb(59 113 202 / 20%);
  border-width: 2px;
  width: 2rem;
  height: 2rem;
}

.btn-rounded {
  border-radius: 50%;
}
