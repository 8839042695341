.modal-scrollable {
  max-height: 200px !important;
  overflow: auto !important;
}

@media (min-width: 1024px) {
  .modal-scrollable {
    max-height: 500px !important;
    overflow: auto !important;
  }
}
