.section-body {
  height: 550px;
  max-height: auto;
}

.section-body-data {
  height: 550px;
  max-height: 100%;
}

.section-body-long {
  min-height: 470px;
  max-height: fit-content;
}

.auto-zoom-font {
  font-size: 10px;
}

.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 2px 4px orange;
  animation-delay: 1.1s;
  background: orange;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.avatar-placeholder {
  height: 32px;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  line-height: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  width: 32px;
  margin-right: 8px;
  display: inline-block;
}

.avatar {
  width: 32px;
  margin-right: 8px;
  display: inline-block;
  border-radius: 50%;
}

div.card-body-groups.card-body {
  height: 550px;
  max-height: 550px;
  overflow: auto;
  overflow-x: hidden;
}

.d-grid {
  display: grid;
}

@media screen and (max-width: 640px) {
  .section-body {
    height: unset !important;
    height: fit-content;
    padding-bottom: 12px !important;

    .map-settings-button-wrapper {
      flex-grow: 1;

      .map-settings-button {
        flex-grow: 1;
        justify-content: center;
      }
    }
  }
}
