@import '../../../globalStyles.scss';

.row-selected {
  background-color: $orangeLighten3;
}

.clickable {
  cursor: pointer;
}

.orange-icon {
  color: $orangeDarken4;
}

.btn {
  padding: 10px 20px;
  font-size: 0.8rem;
}

.col-machines-buttons {
  min-width: 150px;
}

.one-action-button {
  min-width: 40px;
}

.three-action-buttons {
  min-width: 120px;
}

.four-action-buttons {
  min-width: 200px;
}

.table-processing-spinner-icon {
  width: 28px;
  height: 28px;
  margin-right: 8px;
}
