@import '../../../../../globalStyles.scss';

.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 2px 4px orange;
  animation-delay: 1.1s;
  background: orange;
}

.marker:before {
  display: block;
  content: ' ';
  background-image: 'M464,464H48a16,16,0,0,1-14.07-23.62l208-384a16,16,0,0,1,28.14,0l208,384A16,16,0,0,1,464,464Z';
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
}

.marker-label {
  color: black !important;
  background-color: white !important;
  padding: 2px;
  margin: 0px !important;
  border-radius: 2px;
  font-size: 12px !important;
  position: relative !important;
  bottom: 25px;
}

.green-text-color-marker-label {
  color: $greenLighten !important;
}

.orange-text-color-marker-label {
  color: $orangeLighten4 !important;
}

.red-text-color-marker-label {
  color: $redLighten !important;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.avatar-placeholder {
  height: 32px;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  line-height: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  width: 32px;
  margin-right: 8px;
  display: inline-block;
}

.avatar {
  width: 32px;
  margin-right: 8px;
  display: inline-block;
  border-radius: 50%;
}

.gm-style .gm-style-iw-c {
  overflow: hidden;
  padding: 10px !important;
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
  padding: 5px !important;
}

@media screen and (max-width: 600px) {
  .gm-style .gm-style-iw-c {
    overflow: hidden;
    padding: 10px !important;
    max-width: 100% !important;
  }
}
