@import '../src/globalStyles.scss';

/*Modals*/
.modal-text {
  font-size: $FontSize20;
}

.modal-footer {
  border: none;
}

.modal-header {
  background-color: $warning;
}

/*Dropdown*/
.dropdown-menu > li:first-child,
.dropdown-menu > li:last-child {
  border-radius: 0%;
}

li ul a.Ripple-parent.active,
li ul a.Ripple-parent.active span.sv-normal,
.white-skin .side-nav .collapsible li .collapsible-body a:hover {
  color: $orangeDarken4 !important;
}

.side-navigation-active-color {
  color: $orangeDarken4 !important;
}

.select-options-list {
  list-style: none;
  padding: 0;
  position: absolute !important;
  background-color: #fff !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  width: 100% !important;
  max-height: 190px !important;
  overflow-y: auto !important;
}

.select-option.selected.active,
.select-option:hover:not(.disabled) {
  background-color: #fb3;
}

.select-option.active {
  background-color: transparent;
  padding-left: 16px;
}

.select-dropdown li.disabled:first-child {
  display: none;
}

.select-arrow:before {
  content: '\25BC' !important;
}

/*SideNav*/
div.scrollbar-container.ps {
  height: 100% !important;
}

.sidenav-collapse {
  background-color: lightgray;
  padding: 0;
  margin: 0;
}

.sidenav-menu,
.sidenav-collapse {
  padding: 0;
}

.sidenav-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.8rem;
  color: inherit;
  white-space: break-spaces;
  border-radius: 5px;
  transition: all 0.3s linear;
}

.sidenav-collapse.collapsing {
  display: none !important;
}

.sidenav-collapse.collapsing,
.sidenav-item.collapsing {
  transition: all 0.1s ease-out;
  animation: all 0.1s ease-out;
}

.sidenav-collapse.collapse,
.sidenav-item.collapse {
  transition: all 0.2s ease-in;
  animation: all 0.2s ease-in;
}

.sidenav-item .ripple-wave,
.sidenav-item .ripple-wave.active,
.sidenav-item .ripple-surface-info .ripple-wave,
.ripple-surface-primary .ripple-wave {
  background-image: none !important;
  opacity: 0;
}

.collapse .sidenav-link {
  opacity: 0;
  animation: smooth-appear 0.3s ease forwards;
}

@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}

.sidenav-collapse .sidenav-link {
  font-size: 0.8rem;
  height: fit-content;
  padding-left: 3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  transition: all 0.3s ease-in-out;
}

.side-navigation-active-color,
.white-skin .side-nav .collapsible li .collapsible-body a:hover,
li ul a.Ripple-parent.active,
li ul a.Ripple-parent.active span.sv-normal,
.sidenav-primary .sidenav-link:active {
  color: #e65100 !important;
}

.sidenav-primary .sidenav-link:active,
.sidenav-primary .sidenav-link:focus,
.sidenav-primary .sidenav-link:active,
.sidenav-primary .sidenav-link:focus {
  color: #e65100;
  background-color: rgba(59, 113, 202, 0.05);
}

.sidenav-primary .sidenav-item .sidenav-link:hover {
  color: #e65100;
  background-color: rgba(194, 194, 194, 0.318);
  border: none;
}

/*Gradients*/
.peach-gradient {
  /* fallback for old browsers */
  background: #fa709a;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ffd86f, #fc6262);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ffd86f, #fc6262);
}

/*Table*/
.table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

/*Form*/
.form-check-input[type='radio'].with-gap:checked + label:after,
label.btn input[type='radio'].with-gap:checked + label:after {
  transform: scale(0.55);
}

.dropdown-menu .active {
  background-color: orange !important;
}

.form-check-input[type='radio'] + label:before,
.form-check-input[type='radio'] + label:after,
label.btn input[type='radio'] + label:before,
label.btn input[type='radio'] + label:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 20px;
  height: 20px;
  margin: 4px;
  content: '';
  transition: 0.28s ease;
}

.form-outline .form-control:focus,
.form-control:focus {
  border-color: black;
  box-shadow: none;
}

.form-outline .form-control {
  transition: all 0.01s linear;
}

.form-outline .form-control:focus ~ .form-label {
  color: rgba(0, 0, 0, 0.6);
}

.select-option-icon {
  width: 22px;
  height: 22px;
}

.form-check-input:checked,
.form-check-input:focus,
.form-check-input:checked:focus {
  border-color: rgba(0, 0, 0, 0.25);
}

.form-check-input[type='checkbox']:checked,
.form-check-input[type='checkbox']:checked:focus {
  background-image: none;
  background-color: rgba(249, 165, 82, 1);
}

.form-check-input[type='checkbox']:before {
  box-shadow: none !important;
  border: 0.125rem solid rgba(249, 165, 82, 1) !important;
}

.form-check-input[type='checkbox'] {
  margin-left: 0 !important;
  position: initial;
  border: 0.125rem solid #aaa;
}

/*Buttons*/
[class*='btn-outline-'].btn-sm,
.btn-group-sm > [class*='btn-outline-'].btn {
  padding: inherit;
}

[class*='btn-outline-'].btn-sm,
.btn-group-sm > [class*='btn-outline-'].btn {
  padding: inherit;
}

th button.btn.actions-toggle,
td button.btn.actions-toggle {
  border: none !important;
  box-shadow: none !important;
  margin-left: 0 !important;
  padding: 0 0 0 !important;
}

.btn-primary {
  background-color: transparent;
  border-color: transparent;
  background: transparent;
}

.btn-primary,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:visited {
  margin: 5px;
  background-color: #ff9933;
  box-shadow: none !important;
  border-color: transparent !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: transparent;
  background-color: #ff9933;
  border-color: transparent !important;
}

.btn-outline-primary:disabled {
  color: $orangeDark;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: rgb(245, 124, 0);
  background-color: transparent;
  border-color: transparent !important;
  box-shadow: none;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle,
.btn-outline-dark:hover {
  color: black;
  background-color: transparent;
  border-color: transparent;
}

.btn-outline-primary:hover {
  background-color: transparent;
  border-color: transparent;
}

.cancel-onHover:hover,
.cancel-onHover:active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #ffa500;
  background-color: white;
  border-color: #ffa500;
  box-shadow: 0 8px 9px -4px rgb(228 161 27 / 30%), 0 4px 18px 0 rgb(228 161 27 / 20%);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #565050;
  background-color: #e8ecef;
  border: 1px solid;
  border-color: #565050;
}

/*Switches - Radiobuttons - Checkbox*/
.switch label input[type='checkbox']:checked + .lever:after {
  left: 1.5rem;
  background-color: $orangeLighten4;
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: #ffa5004d;
}

.form-switch .form-check-input:checked[type='checkbox']:after {
  background-color: $warning;
}

.form-check-input[type='radio']:checked:after {
  background-color: $orangeDarken4;
}

.form-check-input[type='radio']:checked,
.form-check-input[type='radio']:checked:focus {
  border-color: $orangeDarken4;
}

.form-check-input[type='radio']::before {
  box-shadow: none !important;
}

/*Pagination*/
ul.pagination-circle {
  flex-wrap: wrap;
}

/*DateTimePicker*/
.timepicker-modal.animation {
  animation-fill-mode: none !important;
  animation-name: none !important;
}
