.md-form textarea.md-textarea {
  padding: 0.6rem 0 0.4rem 0;
  overflow-y: hidden;
}

.period-filter-message {
  padding-left: 10px;
  padding-right: 10px;
  white-space: pre-wrap;
  text-align: left;
}

.invalid-feedback {
  margin-top: 0 !important;
}

.form-control {
  margin-bottom: 0 !important;
}
