.page-item.active .page-link {
  color: white;
  background-color: rgb(245, 124, 0);
}

.isDisabled {
  pointer-events: none;
}

.page-link {
  box-shadow: none !important;
}

.page-link-arrows {
  background-color: transparent !important;
  cursor: pointer;
}

.page-link-arrows:hover {
  background-color: rgba(0, 0, 0, 0.125) !important;
}
