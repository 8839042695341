@import '../../../../../globalStyles.scss';

.profile-header {
  background-color: $orangeLighten3;

  .profile-tab {
    &.active-tab {
      border-bottom: 7px solid $orangeDarken4;
    }
  }
}

@media (max-width: 576px) {
  .profile-header {
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
    position: relative;
    white-space: nowrap;
    overflow: auto;
  }
  .profile-tab {
    display: inline-block;
    padding: 0;
  }
}
