@import '../../../../../globalStyles.scss';

.track-history-time-column {
  text-align: left;

  td {
    padding: 0px 0px 5px 0px !important;
    border-top: none !important;
  }
}

.track-history-time-column td:last-of-type {
  display: block;
}

.second-timestamp {
  color: gray;
}
