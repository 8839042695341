@import '../../../../globalStyles.scss';

.collapse-header {
  background-color: #ffffff !important;
}

.date-picker input {
  margin-top: 15px;
}

.hidden-picker input {
  visibility: hidden;
  margin-top: 0;
  height: 0px;
}

.form-check-input[type='radio'].with-gap:checked + label:after,
label.btn input[type='radio'].with-gap:checked + label:after {
  transform: scale(0.55);
}

.form-check-input[type='radio']:checked + label:after,
.form-check-input[type='radio'].with-gap:checked + label:after,
label.btn input[type='radio']:checked + label:after,
label.btn input[type='radio'].with-gap:checked + label:after {
  // background-color: #fc6262;
}

.form-check-input[type='radio']:checked + label:after,
.form-check-input[type='radio'].with-gap:checked + label:before,
.form-check-input[type='radio'].with-gap:checked + label:after,
label.btn input[type='radio']:checked + label:after,
label.btn input[type='radio'].with-gap:checked + label:before,
label.btn input[type='radio'].with-gap:checked + label:after {
  //  border: 2px solid #fc6262;
}

.form-check-input[type='radio'] + label:before,
.form-check-input[type='radio'] + label:after,
label.btn input[type='radio'] + label:before,
label.btn input[type='radio'] + label:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 20px;
  height: 20px;
  margin: 4px;
  content: '';
  transition: 0.28s ease;
}

.chartContainer {
  width: 100%;
  position: relative;
}

.collapse-subtitle {
  margin-left: 20px;
  font-size: 14px;
  color: #6c757d;
}

.highcharts-container,
.highcharts-container svg {
  width: 100% !important;
  height: 100% !important;
}

.float-right {
  float: right;
}

.zIndex-8 {
  z-index: 8;
}

.zIndex-11 {
  z-index: 11;
}

.min-w-200 {
  min-width: 200px !important;
}

.dropdown-menu {
  min-width: 200px !important;
}

.dropdown-sensors-groups {
  max-height: 30rem;
  overflow-y: auto;
  transform: unset !important;
  top: 0px !important;
  right: 180px !important;
  bottom: auto !important;
  left: auto !important;
}

.dropdown-item {
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  border-radius: 0 !important;
}

.dropdown-item:hover {
  background-color: orange !important;
  color: #fff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.dropdown-inner-item {
  text-align: left;
  padding-left: 1rem;
  font-weight: 200 !important;
}

#geo-details-btn {
  background-color: $orangeDarken4 !important;
}

.sensorGroups-btn {
  display: flex;
  justify-content: flex-end;
}

.chart-buttons-container {
  float: right !important;

  .button-collapse-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.hidden-chart {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.button-To-Icon,
.tooltip-text,
.chart-buttons-tooltip,
.custom-icon {
  display: none;
}

.chart-tooltip-mobile > .show.tooltip {
  display: none;
  visibility: hidden !important;
}

@media (min-width: 1401px) and (max-width: 1500px), (max-width: 1255px) {
  .chart-buttons-container-size .chart-buttons-container {
    .padding-for-icon {
      padding: 6px;
    }
  }

  .button-To-Icon {
    display: block;
    color: #ffc008;
    font-size: 20px;
    height: 20px;
    width: 20px;
  }

  .tooltip-text {
    display: block;
    color: white;
    font-size: 14px;
    width: 100%;
    width: max-content;
  }

  .custom-icon {
    display: block;
    height: 30px;
    width: 30px;
  }

  .chart-buttons-tooltip,
  .chart-tooltip-mobile > .show.tooltip {
    display: block;
    visibility: visible !important;
  }

  .button-To-Text {
    display: none;
  }
}

@media (max-width: 768px) {
  .chart-buttons-container-size {
    display: flex;
    justify-content: flex-start;
    padding-left: 26px;
    margin-top: 10px;
  }

  .sensorGroups-btn {
    display: flex;
    justify-content: flex-end;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dropdown-sensors-groups {
    top: 45px !important;
    right: 0px !important;
  }
}

@media (max-width: 576px) {
  .chart-buttons-container-size {
    display: flex;
    justify-content: flex-start;
    padding-left: 26px;
  }

  .chart-buttons-container .button-collapse-spacing {
    padding: 11px;
    margin-top: 10px;
  }

  .sensorGroups-btn {
    display: flex;
    justify-content: flex-start;
    margin-left: 3px;
    margin-bottom: 10px;
  }

  .button-collapse-spacing {
    padding: 1rem;
  }

  .dropdown-without-margin {
    margin: 0;
  }
}
