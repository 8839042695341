/* DateTimePicker colors*/
.MuiPickersLayout-toolbar,
.MuiTabs-indicator,
.MuiClockPointer-root {
  background-color: #ffcc80 !important;
}
.MuiDateTimePickerTabs-root .MuiSvgIcon-root,
.css-1hbyad5-MuiTypography-root {
  color: #e65100 !important;
}
.MuiPickersCalendarHeader-root .MuiSvgIcon-root {
  color: black;
}
.MuiDateTimePickerToolbar-ampmSelection .Mui-selected {
  color: #e65100 !important;
}
.MuiTypography-root .MuiTypography-subtitle1 .MuiPickersToolbartext-root,
.MuiTypography-root .MuiTypography-h4 .MuiPickersToolbarText-root .Mui-selected,
.MuiTypography-root .MuiTypography-h3 .MuiPickersToolbarText-root,
.MuiTypography-root .MuiTypography-subtitle2 .MuiDateTimePickerToolbar-ampmLabel .MuiPickersToolbarText-root,
.MuiButtonBase-root .MuiButton-root .MuiButton-text,
.MuiDialogActions-root .MuiButton-text {
  color: black !important;
}
.MuiButtonBase-root .MuiPickersDay-root .Mui-selected .MuiPickersDay-dayWithMargin,
.MuiButtonBase-root .MuiPickersDay-root .Mui-selected .MuiPickersDay-dayWithMargin .MuiPickersDay-today,
.MuiDateCalendar-root .Mui-selected,
.MuiClockPointer-thumb,
.MuiClock-pin {
  background-color: #e65100 !important;
}
.MuiClockPointer-thumb {
  border-color: #e65100 !important;
}
.MuiDayCalendar-monthContainer .Mui-selected {
  background-color: #e65100 !important;
}
.MuiInputBase-root .MuiOutlinedInput-input {
  height: 0.4375em !important;
}

.MuiPickersToolbar-root > .MuiGrid-root,
div.MuiDateTimePickerToolbar-timeDigitsContainer {
  align-items: end !important;
}

span.MuiTypography-root.MuiTypography-h4.MuiPickersToolbarText-root.Mui-selected,
span.MuiTypography-root.MuiTypography-h3.MuiPickersToolbarText-root {
  font-size: 2.3rem !important;
  line-height: 1.2;
}

span.MuiTypography-root.MuiTypography-subtitle2.MuiDateTimePickerToolbar-ampmLabel.MuiPickersToolbarText-root {
  line-height: 1.2;
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  letter-spacing: 0.08333em;
}

span.MuiTypography-root.MuiTypography-overline {
  font-size: 1rem !important;
}

@media (max-height: 400px) {
  span.MuiTypography-root.MuiTypography-overline {
    line-height: 1;
    font-size: 0.7rem !important;
    margin: 10px 0 !important;
  }

  .MuiPickersToolbar-root > .MuiGrid-root {
    align-items: flex-start !important;
    margin-top: 10px !important;
  }

  span.MuiTypography-root.MuiTypography-h4.MuiPickersToolbarText-root.Mui-selected,
  span.MuiTypography-root.MuiTypography-h3.MuiPickersToolbarText-root,
  span.MuiTypography-root.MuiTypography-h4.MuiPickersToolbarText-root {
    font-size: 1.5rem !important;
    line-height: 1 !important;
  }

  div.MuiDateTimePickerToolbar-timeContainer {
    margin-top: 20px !important;
  }

  div.MuiDateTimePickerToolbar-ampmSelection.MuiDateTimePickerToolbar-ampmLandscape {
    justify-content: space-between !important;
  }

  h4.MuiTypography-root.MuiTypography-h4.MuiTypography-alignLeft.MuiDatePickerToolbar-title {
    font-size: 1.2rem !important;
  }
}
